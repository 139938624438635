import React from 'react';
import { Link } from 'react-router-dom';


const AdolescentGynecology = () => {
    return (
        <div className="adolescent-gynecology-container">
            <h1 className="main-heading">Gynecological Problems During Adolescence</h1>
            <p className="intro-text">
                Adolescence is a transformative period in a girl's life, characterized by significant physical, emotional, and hormonal changes. During this time, young girls may face various gynecological challenges that can impact their overall health and well-being. Understanding these issues and seeking early intervention is crucial for maintaining long-term reproductive health. Common problems include:
            </p>

            <div className="issue-section">
                <h2>1. Menstrual Irregularities</h2>
                <p className="description">Description: Adolescents often experience irregular periods, heavy bleeding (menorrhagia), or painful periods (dysmenorrhea) due to hormonal fluctuations.</p>
                <p className="importance">Importance: Addressing these issues early can prevent future complications and improve quality of life.</p>
                <p className="clinic-care">Naari Clinic Care: Our specialists provide personalized care to manage menstrual irregularities, ensuring that young girls receive the support they need.</p>
            </div>

            <div className="issue-section">
                <h2>2. Polycystic Ovary Syndrome (PCOS)</h2>
                <p className="description">Description: PCOS is a common condition that often begins during adolescence, characterized by irregular periods, acne, and excessive hair growth.</p>
                <p className="importance">Importance: Early diagnosis and management are key to preventing long-term complications such as infertility and metabolic disorders.</p>
                <p className="clinic-care">Naari Clinic Care: At Naari Clinic, we offer comprehensive care plans tailored to the needs of adolescents with PCOS, focusing on hormonal balance and lifestyle modifications.</p>
            </div>

            <div className="issue-section">
                <h2>3. Vaginal Infections</h2>
                <p className="description">Description: Changes in hygiene practices or hormonal shifts can lead to yeast infections and bacterial vaginosis, causing discomfort and abnormal discharge.</p>
                <p className="importance">Importance: Timely treatment prevents complications and promotes overall vaginal health.</p>
                <p className="clinic-care">Naari Clinic Care: Our team provides accurate diagnosis and effective treatment options, helping young girls maintain optimal reproductive health.</p>
            </div>

            <div className="issue-section">
                <h2>4. Endometriosis</h2>
                <p className="description">Description: Although less common in teenagers, endometriosis can start in adolescence, leading to severe menstrual pain and potential fertility concerns if untreated.</p>
                <p className="importance">Importance: Early intervention can reduce the risk of long-term complications and improve quality of life.</p>
                <p className="clinic-care">Naari Clinic Care: We offer advanced diagnostic tools and treatments to manage endometriosis, ensuring that adolescents receive the care they need to thrive.</p>
            </div>

            <div className="issue-section">
                <h2>5. Pelvic Pain</h2>
                <p className="description">Description: Pelvic pain not related to menstruation can arise from various underlying conditions and should be evaluated by a healthcare professional.</p>
                <p className="importance">Importance: Identifying the cause of pelvic pain is essential for appropriate treatment and pain relief.</p>
                <p className="clinic-care">Naari Clinic Care: Our gynecologists provide thorough evaluations and personalized treatment plans to address the root cause of pelvic pain.</p>
            </div>

            <div className="issue-section">
                <h2>6. Delayed Puberty</h2>
                <p className="description">Description: Some adolescents may experience delayed onset of menstruation or other puberty-related changes, which could indicate underlying health issues.</p>
                <p className="importance">Importance: Early assessment and intervention can help address any underlying conditions and support healthy development.</p>
                <p className="clinic-care">Naari Clinic Care: We offer comprehensive evaluations to determine the cause of delayed puberty and provide guidance for appropriate treatment.</p>
            </div>

            <div className="clinic-info">
                <h2>Why Choose Naari Clinic?</h2>
                <p className="intro-text">
                    At Naari Clinic, we understand the unique needs of adolescent girls. Our experienced gynecologists provide compassionate care, focusing on early diagnosis and effective management of gynecological problems. By addressing these issues during adolescence, we aim to ensure a healthy transition into adulthood.
                </p>
                <p className="visit-link">
                    If you or your child are experiencing any of these issues, consulting a gynecologist at Naari Clinic is recommended for proper care and guidance. <Link to="/contact" className="contact-link">Visit us today</Link> to start your journey towards better reproductive health.
                </p>
            </div>
        </div>
    );
};

export default AdolescentGynecology;
