import React from 'react';


const Faq = () => {
  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      <br/>
      <div className="faq-item">
        <h3>1. Can I visit a gynaecologist & obstetrician doctor if I am experiencing irregular periods?</h3>
        <p>Yes, you can visit a gynaecologist & obstetrician when facing irregular periods and other similar problems.</p>
      </div>
      <div className="faq-item">
        <h3>2. Does Dr. Rekha Dubey (The Naari Clinic) perform surgeries?</h3>
        <p>Most gynaecologists & obstetricians perform surgeries such as c-sections, fibroid removal, ovarian cyst removal, etc. For more details, you can get in touch with Dr. Rekha Dubey (The Naari Clinic).</p>
      </div>
      <div className="faq-item">
        <h3>3. Do gynaecologists & obstetricians deliver babies?</h3>
        <p>Yes, gynaecologists & obstetricians are trained in helping women in labour and delivering babies.</p>
      </div>
      <div className="faq-item">
        <h3>4. What does Dr. Rekha Dubey (The Naari Clinic) in Kanpur Rd specialise in?</h3>
        <p>Dr. Rekha Dubey (The Naari Clinic) specialises in Gynecology and Obstetrics.</p>
      </div>
      <div className="faq-item">
        <h3>5. How do I book an appointment with Dr. Rekha Dubey (The Naari Clinic)?</h3>
        <p>The working timings of Dr. Rekha Dubey (The Naari Clinic) are Monday to Sunday: Open 24 Hrs. You can book an appointment by calling the clinic.</p>
      </div>
    </div>
  );
}

export default Faq;
