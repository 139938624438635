import React from 'react'

const Treatment = () => {
    return (
        <>

            <br />
            <center>


                <h1>Gynecologist and Obstetrician in Lucknow   </h1>


            </center>

           
            <br />

            <div className='treatment'>

                <div className='treatment2'>
                    <h1>PCOS/PCOD Treatment</h1>
                    <p> There's no single test to specifically diagnose polycystic ovary syndrome (PCOS). Your health care provider is likely to start with a discussion of your symptoms, medications and any other medical conditions. </p>
                </div>

                <div className='treatment2'>
                    <h1>Birth Control</h1>
                    <p>Birth control only works when you use it correctly and consistently. Forms of birth control that are more convenient and don't require much maintenance are usually most effective. These forms include the birth control implant, intrauterine devices (IUDs) and sterilization</p>
                </div>

                <div className='treatment2'>
                    <h1>Adolescent Care</h1>
                    <p>What is adolescent care? Adolescent care is critical care for children ages 10 to 15 who seek autonomy during a transitional phase of their life. </p>
                </div>

                <div className='treatment2'>
                    <h1>Infertility</h1>
                    <p>Infertility is a disease of the male or female reproductive system defined by the failure to achieve a pregnancy after 12 months or more of regular unprotected sexual intercourse
                    </p>
                </div>

            </div>


            <div className='treatment'>

                <div className='treatment2'>
                    <h1>High Risk Pregnancy</h1>
                    <p>A high-risk pregnancy is one in which a woman and her fetus face a higher-than-normal chance of experiencing problems. These risks may be due to factors in the pregnancy itself, or they may stem from preexisting maternal medical conditions, such as cancer, diabetes, or lupus.
                    </p>
                </div>

                <div className='treatment2'>
                    <h1>Ectopic pregnancy</h1>
                    <p> An ectopic pregnancy occurs when a fertilized egg implants and grows outside the main cavity of the uterus</p>
                </div>

                <div className='treatment2'>
                    <h1>Placenta Issues</h1>
                    <p>The placenta is a vital organ that forms during pregnancy, providing essential oxygen and nutrients to the developing fetus. Comprised of tissues, fluids, and other materials, the placenta envelops and supports the embryo within the uterus.
                    </p>
                </div>

                <div className='treatment2'>
                    <h1>Prenatal Care</h1>
                    <p>Prenatal care is the health care you get while you are pregnant. It includes your checkups and prenatal testing. Prenatal care can help keep you and your baby healthy. </p>
                </div>

            </div>


        </>
    )
}

export default Treatment