import React from 'react';
import Footer from './Footer';

const DrRekha = () => {
  return (
    <>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
          <h1 style={{ textAlign: 'center', color: '#333' }}>THE NAARI CLINIC</h1>
          <h2 style={{ textAlign: 'center', color: '#555' }}>Dr. Rekha Dubey</h2>
          <p style={{ textAlign: 'center', color: '#777' }}>
            MBBS, DGO, MS (DNB)<br/>
            Batra Hospital, New Delhi<br/>
            FMAS, Reg no: 70209
          </p>
          <h3 style={{ color: '#333' }}>Clinic Information</h3>
          <p style={{ color: '#555' }}>
            LDA Colony, Ashiyana, Lucknow<br/>
            Email: thenaariclinic@gmail.com<br/>
            For Appointment: 9236256096<br/>
           
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DrRekha;
