import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import Home from './component/Home';
import Navbar from './component/Navbar';
import Head from './component/Head';
import Appointment from './component/Appointment';
import About from './component/About';
import DrRekha from './component/DrRekha';

function App() {
  return (
    <div className="Main">
      <Router>
        <Head/>
        <Navbar/>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/Appointment" element={<Appointment />} />
          <Route path="/About" element={<About />} />
          <Route path="/DrRekha" element={<DrRekha />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
