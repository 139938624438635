import React from 'react'
import Carousal from './Carousal';
import AboutSection from './AboutSection';
import Treatment from './Treatment';
import Footer from './Footer';
import Faq from './faq';
import AdolescentGynecology from './AdolescentGynecology';

const Home = () => {
  return (
    <div className='Main'>

      <Carousal />
      <AboutSection />
      <Treatment />
      <br/><hr/>
      <AdolescentGynecology/>
      <Faq />
      <Footer />


    </div>
  )
}

export default Home