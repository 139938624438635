import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='foot'>
                <h1>Quick Links</h1>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Home</p></Link>
                <Link to='' className='link'><p> <i class="fa fa-angle-right"></i>  About</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Service</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Gallery</p></Link>
               

            </div>


            <div className='foot'>
                <h1>Others</h1>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Doctors</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Appointment</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Clinic</p></Link>
              

            </div>

            <div className='foot'>
                <h1>Our Services</h1>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Menstrual Irregularities</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Polycystic Ovary Syndrome</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Vaginal Infections</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Endometriosis</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Pelvic Pain</p></Link>
                <Link to='' className='link'><p><i class="fa fa-angle-right"></i> Delayed Puberty</p></Link>

            </div>
            <div className='foot'>
                <h1>Contact Info</h1>
                <br />
                <p> <i class="fa fa-map-marker"></i> 1st Floor KS Tower Near SKD Hospital LDA Colony Ashiyana Lucknow, Uttar Pradesh</p>

                <p> <i class="fa fa-phone"></i> +91 9236256096</p>
                <p> <i class="fa fa-envelope"></i> thenaariclinic@gmail.com</p>


            </div>
        </div>
    )
}

export default Footer