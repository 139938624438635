import React from 'react'

const Head = () => {
    return (
        <div className='head'>
            <div className='head1'>
                <p> Location : 1st Floor KS Tower Near SKD Hospital LDA Colony Ashiyana Lucknow, Uttar Pradesh </p>
            </div>
            <div className='head2'>
                <a href="https://www.google.com/search?q=The+Naari+Clinic&stick=H4sIAAAAAAAA_-NgU1I1qDC2tExKSzEysbAwMjYyTjS1MqhINrawTE1MTjJIskgzMzJLXsQqEJKRquCXmFiUqeCck5mXmQwADRuFwzwAAAA&hl=en&mat=CUP4FvHz8RCIElYBEKoLaeGM88my-8DAn_C4A-Cih3WMEg9p2lCmCek38UZUswoJjxppFSFWThmfoHe0nqh1AYSaMFxnClzixzPEpM44AfZ-E9YZzJ5KoZxEmjKwBdoUhQ&authuser=0" style={{ textDecoration: 'none', color: 'white' }}> <span>Discover  Dr. Rekha Dubey Naari  Clinic</span> Google Page   </a>
                <a href=" "> <i class="fa fa-facebook-f"></i></a>
                <a href=" "> <i class="fa fa-instagram"></i></a>
                <a href=" "> <i class="fa fa-youtube-play"></i></a>
            </div>
        </div>
    )
}

export default Head