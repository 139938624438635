import React from 'react'
import about from '../img/about.jpg'

const AboutSection = () => {
    return (
        <div className='AboutSection'>
            <div className='AboutSection2'>
                <h1>*Welcome to The Naari Clinic, Lucknow*</h1>

                <p>Dr. Rekha Dubey, a highly qualified and experienced obstetrician and gynecologist, leads The Naari Clinic. With an MBBS, DGO, and MS (DNB) from Batra Hospital and Medical Research Centre, New Delhi, Dr. Dubey brings extensive expertise in cesarean delivery, laparoscopic surgery, and the management of all types of obstetric and gynecological conditions.</p>
                <p>At The Naari Clinic, we are committed to providing comprehensive, compassionate care tailored to the unique needs of women at every stage of life.</p>

            </div>
            <div className='AboutSection2'>
                <img src={about} />
            </div>
        </div>
    )
}

export default AboutSection