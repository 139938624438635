import React, { useState } from 'react';
import Footer from './Footer';


const Appointment = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    date: '',
    time: '',
    contact: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the message
    const message = `Hello, I would like to book an appointment with the following details:
      Name: ${formData.name}
      Email: ${formData.email}
      Address: ${formData.address}
      Date: ${formData.date}
      Time: ${formData.time}
      Contact: ${formData.contact}`;

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);

    // WhatsApp URL with encoded message and phone number
    const whatsappUrl = `https://wa.me/919236256096?text=${encodedMessage}`;

    // Open the URL in a new tab/window
    window.open(whatsappUrl, '_blank');
  };

  return (

    <>


      <div className='contact'>

        <div className='contact2'>


          <div className="appointment-container">
            <h2>Book an Appointment</h2>
            <form onSubmit={handleSubmit} className="appointment-form">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date:</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">Time:</label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="contact">Contact Number:</label>
                <input
                  type="tel"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>

        </div>

        <div className='contact2'>

        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3561.3147785603924!2d80.89666337543552!3d26.79810397671574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQ3JzUzLjIiTiA4MMKwNTMnNTcuMyJF!5e0!3m2!1sen!2sin!4v1722505986059!5m2!1sen!2sin"     style={{ width: '100%', height: '100%', border: '0' }} ></iframe>
        </div>

        

      </div>

      <Footer/>
    </>
  );
};

export default Appointment;
