import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import banner from '../img/banner.jpg';
import banner2 from '../img/banner2.jpg';
import { Link } from 'react-router-dom';

const Carousal = () => {
    return (
        <Carousel interval={3000} fade={true} className='slider'>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={banner}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>Best Gynecological Services In Lucknow</h3>
                    <p>Dr. Rekha is one of the best Gynecologist Obstetricians & Laparoscopic Surgeons in Lucknow, Uttar Pradesh. She specializes in the treatment of High-Risk Pregnancy, Infertility, PCOS/PCOD, Menopause, Advanced Laparoscopy, and Hysteroscopy, as well as Uro gynecology treatment in India.</p>

                 
                    <Link to='/Appointment' className="button-link"><button className="button">CONTACT US </button></Link>

                </Carousel.Caption>
              
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={banner2}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>We offers all types of Gynecological Treatments </h3>
                    <p>Dr. Rekha is one of the best Gynecologist Obstetricians & Laparoscopic Surgeons in Lucknow, Uttar Pradesh. She specializes in the treatment of High-Risk Pregnancy, Infertility, PCOS/PCOD, Menopause, Advanced Laparoscopy, and Hysteroscopy, as well as Uro gynecology treatment in India.</p>
                    <Link to='/Appointment' className="button-link"><button className="button">CONTACT US </button></Link>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default Carousal;
