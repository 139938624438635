import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>

            <div className='navigation'>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand" >
                            <b>Dr. Rekha Dubey </b>  <br /> <span>The Naari Clinic </span>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={expanded} aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><Link to="/About" className="dropdown-item" onClick={handleNavItemClick}>About Us</Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Our Gallery</Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Testimonials</Link></li>

                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Gynecology
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> PCOS/PCOD Treatment </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Birth Control </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Adolescent Care </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Infertility </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Menopause </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Well Woman Health-check </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Vaccinations </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Antenatal Yoga </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Abnormal Bleeding/Fibroids </Link></li>
                                        <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}> Hysterectomy </Link></li>
                                    </ul>

                                </li>


                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Obstetric
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                        <li><Link to="/high-risk-pregnancy" className="dropdown-item" onClick={handleNavItemClick}>High Risk Pregnancy</Link></li>
                                        <li><Link to="/ectopic-pregnancy" className="dropdown-item" onClick={handleNavItemClick}>Ectopic Pregnancy</Link></li>
                                        <li><Link to="/placenta-issues" className="dropdown-item" onClick={handleNavItemClick}>Placenta Issues</Link></li>
                                        <li><Link to="/prenatal-care" className="dropdown-item" onClick={handleNavItemClick}>Prenatal Care</Link></li>
                                        <li><Link to="/caesarean-section" className="dropdown-item" onClick={handleNavItemClick}>Caesarean Section</Link></li>
                                        <li><Link to="/postpartum-hemorrhage-treatment" className="dropdown-item" onClick={handleNavItemClick}>Postpartum Hemorrhage Treatment</Link></li>
                                        <li><Link to="/preconceptional-counselling" className="dropdown-item" onClick={handleNavItemClick}>Preconceptional Counselling</Link></li>
                                        <li><Link to="/laparoscopic-fibroid-removal-surgery" className="dropdown-item" onClick={handleNavItemClick}>Laparoscopic Fibroid Removal Surgery</Link></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Doctor's</Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><Link to="/DrRekha" className="dropdown-item" onClick={handleNavItemClick}>
                                            Dr. Rekha Dubey </Link></li>


                                    </ul>
                                </li>



                                <li className="nav-item">
                                    <Link to="/Appointment" className="nav-link" onClick={handleNavItemClick}> Appointment</Link>
                                </li>

                            </ul>


                        </div>
                    </div>
                </nav>

            </div>
        </>
    );
};

export default Navbar;
